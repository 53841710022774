import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
            Welcome to my personal website! As a freelancer specializing in IT, Creative Designing, and Digital Marketing, 
            I take great pride in providing high-quality services to my clients. This Terms & Policy page outlines the 
            terms and conditions that apply to the use of my website and the services I offer.
            </p>
            <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Terms of Use
            </h3>
         
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
             1. Acceptance of Terms
            </h5>
            <p>
            By using my website and availing of my services, 
            you agree to be bound by the following terms and conditions. 
            If you do not agree to these terms, you should not use this website or engage my services.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              2. Intellectual Property
            </h5>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              3. Confidentiality
            </h5>
            <p>
            I respect the privacy of my clients and will treat all information shared with me in the strictest confidence. 
            I will not disclose any information about my clients or their projects without their express written consent.
            </p>
            <h5 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              4. Payment Terms
            </h5>
            <p>
            Payment for my services is due upon completion of the project or as otherwise agreed upon between myself and the client. 
            Payment may be made through a secure online payment gateway or via other agreed-upon means.
            </p>
            <h5 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              5. Refunds
            </h5>
            <p>
            Refunds for services rendered are generally not available. 
            However, if there are issues with the quality of the work I have provided, 
            I will work with my clients to address and resolve these issues.
            </p>
            <h5 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              6. Modifications to Terms
            </h5>
            <p>
            I reserve the right to modify these terms and conditions at any time without prior notice. 
            Any such modifications will be effective immediately upon posting on this website.
            </p>
            <h5 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              7. Disclaimer of Warranties
            </h5>
            <p>
            I make no representations or warranties of any kind, express or implied, with respect to the services I offer. 
            I disclaim all warranties, including but not limited to warranties of merchantability and fitness for a particular purpose.
            </p>
            <h5 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              8. Limitation of Liability
            </h5>
            <p>
            I shall not be liable for any damages, whether direct, indirect, incidental, 
            special, or consequential, arising from the use of my website or the services I offer.
            </p>

            <p>
            By using my website and engaging my services, you agree to these terms and conditions. 
            If you have any questions or concerns about these terms, please do not hesitate to contact me.
            </p>





            {/* <ol className="lh-lg">
              <li>
                Lisque persius interesset his et, in quot quidam persequeris
                vim, ad mea essent possim iriure.
              </li>
              <li>
                Quidam lisque persius interesset his et, Lisque persius
                interesset his et, in quot quidam persequeris vim, ad mea essent
                possim iriure.
              </li>
              <li>
                In quot quidam persequeris vim, ad mea essent possim iriure.
                Quidam lisque persius interesset his et.
              </li>
              <li>
                Quidam lisque persius interesset his et, Lisque persius
                interesset his et.
              </li>
              <li>
                Interesset his et, Lisque persius interesset his et, in quot
                quidam persequeris vim, ad mea essent possim iriure.
              </li>
              <li>
                Persius interesset his et, Lisque persius interesset his et, in
                quot quidam persequeris vim, ad mea essent possim iriure.
              </li>
              <li>
                Quot quidam persequeris vim Quidam lisque persius interesset his
                et, Lisque persius interesset his et, in quot quidam persequeris
                vim, ad mea essent possim iriure.
              </li> *
            * </ol> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
