import React from "react";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Poorna Abeytunga,</span> a Solution
             Provider
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
            Hi there! I'm a multi-talented freelancer specializing in 
            System Administration, System Support, Creative Designing, and Brand Marketing. 
            With years of experience in these fields, I have developed a passion for helping 
            individuals and businesses achieve their goals through effective technology and branding solutions.
            
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
            As a detail-oriented and results-driven professional, 
            I have a knack for troubleshooting and resolving complex system issues, 
            designing eye-catching visuals that resonate with target audiences, 
            and crafting compelling marketing strategies that drive growth and engagement.
            </p>
            
            <p className={darkTheme ? "text-white-50" : ""}>
            Whether you need assistance with system setup and maintenance, creative design and branding, 
            or marketing and advertising, I'm here to help you bring your ideas to life and achieve your objectives. 
            Let's work together to take your business to the next level!
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Poorna Abeytunga
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:contact@poornaabey.com">hello@poornaabey.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>32
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Colombo , Sri Lanka
                </li>
              </ul>
              <a
                href="https://www.linkedin.com/in/poornaabey/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary rounded-pill"
              >
                Visit my LinkedIn
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>8</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years of Experience
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>30</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>50</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>2</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Startups
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
