import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
              The information provided on this website is for general informational purposes only. 
              All creative and tech domain work offered on this site is subject to availability 
              and individual project requirements.
              
            </p>
            <ul className="lh-lg">
              <li>
                While we strive to ensure the accuracy and reliability of the information and services provided, 
                we make no representations or warranties of any kind, express or implied, about the completeness, 
                accuracy, reliability, suitability, or availability with respect to the website or the information, 
                products, services, or related graphics contained on the website for any purpose
              </li>
              <li>
                Any reliance you place on such information is therefore strictly at your own risk. 
                In no event will we be liable for any loss or damage including without limitation, 
                indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss 
                of data or profits arising out of, or in connection with, the use of this website.
              </li>
           
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
